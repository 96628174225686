import { createTRPCReact } from "@trpc/react-query";
import type { AppRouter } from "@watt/api/src";

export const trpcClient = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn();
        await opts.queryClient.invalidateQueries();
      }
    }
  }
});

export { type RouterInputs, type RouterOutputs } from "@watt/api/src";
